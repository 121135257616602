import React, { useState, useEffect, useCallback } from "react"
import * as styles from "./list.module.css"
import OrderMenu from "components/tools/orderMenu"
import Layout from "components/common/layout.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getNoticeListAsync } from "store/modules/postReducer.js"
import Pagenation from "components/tools/pagination.js"
import { getDateYMD, getThumb } from "utils"
import Menu from "components/class/room/menu.js"
import { navigate } from "gatsby-link"
import queryString from "query-string"
const List = props => {
  const dispatch = useDispatch()
  const getNoticeList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getNoticeListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )
  const [order, setOrder] = useState(1)
  const { page } = queryString.parse(props.location.search)
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 7

  const { noticeList, noticeTotal } = useSelector(
    ({ postReducer: { notice } }) => notice,
    shallowEqual
  )

  console.log(noticeList)

  useEffect(() => {
    ;(async () => {
      await getNoticeList(currentPage, pagePostSize, order)
    })()
  }, [order])
  return (
    <Layout>
      <div className={styles.con}>
        <div className={styles.title}>
          피치서가의 특별한 소식을 전해드립니다
        </div>
        <div style={{ marginBottom: "50px" }}>
          <OrderMenu
            order={order}
            setOrder={setOrder}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div>
          {/* {noticeList.map(post => (
            <div key={post.seq_no}>
              <div>{post.title}</div>
              <div>{getDateYMD(post.registerTime, "YYYY.MM.DD")}</div>
              <div>{post.hit}</div>
            </div>
						post.bodyText
          ))} */}
          {noticeList
            .filter(post => post.status === 0)
            .map(post => (
              <Menu
                key={post.news_no}
                img={getThumb(post.thumb_title_uuid)}
                news={true}
                title={post.title}
                hit={post.hit}
                date={getDateYMD(post.registerTime, "YYYY.MM.DD")}
                writer="피치서가"
                onClick={() => {
                  navigate(
                    `/notice/detail/?news_no=${post.news_no}&currentPage=${currentPage}&pagePostSize=${pagePostSize}`
                  )
                }}
              />
            ))}
        </div>
        <Pagenation
          total={noticeTotal}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Layout>
  )
}

export default List
